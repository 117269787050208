<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('new_register')+' / '+$t('summary')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('new_register')+' / '+$t('summary')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form" ref="printPdf">
                <div class="col-12">
                    <div class="mb-4 d-flex justify-content-end">
                        <h6><b-button variant="primary" class="no-button" @click="printPage">{{$t('print')}}</b-button></h6>
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('student_information') | toUpperCase }}</h6>
                    </div>
                    <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                        <div class="row">
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('semester')">
                                    {{ studentData.semester_name }}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('registration_type')">
                                    {{ getLocaleText(studentData,'type_name') }}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('scholarship_rate')">
                                    {{ studentData.scholarship_rate }}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('faculty')">
                                    {{ getLocaleText(studentData,'faculty_name') }}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('program')">
                                    {{ getLocaleText(studentData,'program_name') }}
                                </b-form-group>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="!isInterStudent(studentData.type)">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('identity') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('tc_no')">
                                {{ studentData.national_id }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('name')">
                                {{ studentData.name }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('surname')">
                                {{ studentData.surname }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('date_of_birth')">
                                {{ studentData.birthdate }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('birthplace')">
                                {{ studentData.birthplace }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('gender')">
                                {{ studentData.gender }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('nationality')">
                                {{ getLocaleText(studentData,'nationality_code_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('mother_name')">
                                {{ studentData.mother_name }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('father_name')">
                                {{ studentData.father_name }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('city_name')">
                                {{ studentData.city_name != null ?  studentData.city_name : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('district_name')">
                                {{ studentData.district_name != null ?  studentData.district_name : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12" v-if="isInterStudent(studentData.type)">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('foreign_student_identity') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('name')">
                                {{ studentData.name }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('surname')">
                                {{ studentData.surname }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('gender')">
                                {{ studentData.gender }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('nationality')">
                                {{ getLocaleText(studentData,'nationality_code_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('foreign_document_type')">
                                {{ getLocaleText(studentData,'foreign_document_type') }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('foreign_document_number')">
                                {{ studentData.foreign_document_number }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('passport_country')">
                                {{ getLocaleText(studentData,'passport_country_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('passport_number')">
                                {{ studentData.passport_number }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('residence_permit_no')">
                                {{ studentData.residence_permit_no != null ?  studentData.residence_permit_no : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('residence_permit_start_date')">
                                {{ studentData.residence_permit_start_date != null ?  studentData.residence_permit_start_date : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('residence_permit_end_date')">
                                {{ studentData.residence_permit_end_date != null ?  studentData.residence_permit_end_date : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('father_name')">
                                {{ studentData.father_name }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('address') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('city')">
                                {{ studentData.address_city_name != null ?  studentData.address_city_name : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('district')">
                                {{ studentData.address_district != null ?  studentData.address_district : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('address')">
                                {{ studentData.address != null ?  studentData.address : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('family_info') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('mother_job')">
                                {{ studentData.mothers_job != null ?  studentData.mothers_job : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('mother_gsm')">
                                {{ studentData.mothers_phone != null ?  studentData.mothers_phone : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('father_job')">
                                {{ studentData.fathers_job != null ?  studentData.fathers_job : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('father_gsm')">
                                {{ studentData.fathers_phone != null ?  studentData.fathers_phone : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('contact') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('gsm_no')">
                                {{ studentData.mobile_tel != null ?  studentData.mobile_tel : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('home_tel')">
                                {{ studentData.home_tel != null ?  studentData.home_tel : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('email')">
                                {{ studentData.email != null ?  studentData.email : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('emergency_contact_txt') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('degree_of_proximity')">
                                {{ studentData.emergency_person_relation != null ?  studentData.emergency_person_relation : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('name_surname')">
                                {{ studentData.emergency_person_name != null ?  studentData.emergency_person_name : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('mobile_number')">
                                {{ studentData.emergency_person_phone != null ?  studentData.emergency_person_phone : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('health') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('surgery_illness_history')">
                                {{ studentData.surgery_illness_history != null ?  studentData.surgery_illness_history : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('medicines')">
                                {{ studentData.medicines != null ?  studentData.medicines : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('disability_type')">
                                {{ studentData.type_of_disability != null ?  studentData.type_of_disability : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('disability_rate')">
                                {{ studentData.disability_rate != null ?  studentData.disability_rate : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('education_info') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_education_status')">
                                {{ studentData.edudation_status != null ?  studentData.edudation_status : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_university')">
                                {{ studentData.last_university_name != null ?  studentData.last_university_name : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_school_registration_date')">
                                {{ studentData.last_school_registration_date != null ?  studentData.last_school_registration_date : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_school_date_of_graduation')">
                                {{ studentData.last_school_date_of_graduation != null ?  studentData.last_school_date_of_graduation : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_school_diploma_number')">
                                {{ studentData.last_school_diploma_number != null ?  studentData.last_school_diploma_number : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('last_school_diploma_point')">
                                {{ studentData.last_school_diploma_point != null ?  studentData.last_school_diploma_point : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('requests_info') | toUpperCase }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('want_leave_of_absence')">
                                {{ studentData.want_leave_of_absence != null ?  studentData.want_leave_of_absence : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('leave_of_absence_reason')">
                                {{ studentData.reason_for_leave_of_absence != null ?  studentData.reason_for_leave_of_absence : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('leave_of_absence_semesters')">
                                {{ studentData.leave_of_absence_semesters != null ?  studentData.leave_of_absence_semesters : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('want_optional_prep')">
                                {{ studentData.want_optional_prep != null ?  studentData.want_optional_prep : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('do_you_have_language_proficiency')">
                                {{ studentData.has_english_proficiency_certificate != null ?  studentData.has_english_proficiency_certificate : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('cert_type')">
                                {{ studentData.english_proficiency_certificate_type != null ?  getLocaleText(studentData,'english_proficiency_certificate_type_name') : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('exam_date')">
                                {{ studentData.english_proficiency_exam_date != null ?  studentData.english_proficiency_exam_date : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('exam_score')">
                                {{ studentData.english_proficiency_exam_score != null ?  studentData.english_proficiency_exam_score : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('toefl_username')">
                                {{ studentData.toefl_username != null ?  studentData.toefl_username : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-4 col-xl-3">
                            <b-form-group :label="$t('toefl_password')">
                                {{ studentData.toefl_password != null ?  studentData.toefl_password : '-' }}
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2 d-flex justify-content-between">
                        <h6 class="mb-0">{{ $t('documents') | toUpperCase }}</h6>
                        <h6>{{ $t('document_due_date') }}</h6>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div v-for="(item,index) in documents" v-bind:key="index">
                                <b-row no-gutters class="file-upload-box mb-1">
                                    <b-col sm="12" md="8" lg="8">
                                        <div class="filename d-flex" :class="{'active':item.uuid}">
                                            <span class="flex-grow-1">{{ getLocaleText(item,'name') }}</span>
                                        </div>
                                    </b-col>
                                    <b-col sm="12" md="4" lg="4" v-if="item.due_date">
                                        <div class="filename d-flex">
                                            <span class="flex-grow-1">{{item.due_date}}</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="mb-2">
                        <p v-html="$t('kvkk_content')"></p>
                        <p>{{$t('summary_statement_content')}}</p>
                        <p class="text-center">
                            <span>{{$t('signature')}}</span>
                            <br>
                            <strong>{{studentData.name+' '+studentData.surname}}</strong>
                        </p>
                    </div>
                </div>

            </div>
        </app-layout>
    </div>
</template>
<script>
    import Header from "@/layouts/AppLayout/Header";
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

    import AppLayout from "@/layouts/AppLayout"
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import RegistrationService from "@/services/RegistrationService";

    export default {
        components: {
            AppLayout,
            ValidationObserver,
            ValidationProvider,
            Header,
            HeaderMobile
        },
        metaInfo() {
            return {
                title: this.$t('new_register')+' / '+this.$t('summary')
            }
        },
        data() {
            return {
                studentData: {},
                documents: []
            }
        },
        methods: {
            getStudentData(){
                RegistrationService.show(this.$route.params.id).then(response => {
                    this.studentData = response.data.data
                    this.studentData.registration_files.forEach((item) => {
                        this.studentData.missing_documents.forEach((doc) => {
                            if(item.id == doc.document_type_id){
                                item.due_date = doc.document_due_date
                            }
                        })
                        this.documents.push(item)
                    })
                })
            },

            isInterStudent(value){
                if(value == 203 || value == 232 || value == 233 || value == 234 || value == 235){
                    return true
                } else {
                    return false
                }
            },

            printPage(){
                window.print()
            }
        },



        created() {
            this.getStudentData()
        }
    };
</script>
<style scoped>
@media print {
    body{
        -webkit-print-color-adjust: exact !important;
    }

    .no-button{
        display: none;
    }
}
</style>

